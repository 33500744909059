import React, {createContext, useState, useRef, useMemo, useCallback} from 'react';
import { patterns, validateForDestopDoorDelivery, validateForDestopPickup, ValidateForMobileDoorDelivery, ValidateForMobilePickup } from '../utils/Validations';

export const AppContext = createContext(null)

const AppContextProvider = ({children}) => {
    const [mobileCheckoutModal, setMobileCheckoutModal] = useState(false);
    const [resetPasswordOtp, setResetPasswordOtp] = useState("");
    const [currentUrl, setCurrentUrl] = useState("/")
    const [copySuccess, setCopySuccess] = useState('');
    const [ itemLimit, setItemLimit] = useState(8);
    const [pageNumber, setPageNumber] = useState(1)
    const [categoryName, setcategoryName] = useState("")
    const [copied, setCopied] = useState(false);
    const [openShareModal, setOpenShareModal] = useState(false);
    const [socialMediaShareOpen,setSocialMediaShareOpen] = useState(false);
    const [openDownload, setOpenDownload] = useState(false);
    const [page,setPage] = useState(1)
    const about = useRef();
    const faq = useRef();
    const price = useRef();
    const phoneDoorDeliveryRef = useRef(null);
    const emailDoorDeliveryRef = useRef(null);
    const phonePickupRef = useRef(null);
    const emailPickupRef = useRef(null);
    const mobileeDoorDeliveryPhoneRef = useRef(null);
    const mobileeDoorDeliveryEmailRef = useRef(null);
    const mobilePickupPhoneRef = useRef(null);
    const mobilePickupEmailRef = useRef(null);   
    const mobileDeliveryMethodRef = useRef(null) 

    const scrollToSection = (elementRef) => {
        window.scrollTo({
             top: elementRef.current.offsetTop,
             behavior: "smooth"
        })
  }
  const slugify = useCallback((str) => {
    return str.trim().replace(/\s+/g, "-").toLowerCase()
},[])
const removeSlug = useCallback((str) => {
  return str.trim().replace(/-+/g, " ").toLowerCase()
},[]);
const normalizeCase = useCallback((t) => {
  const words = t?.toLowerCase().split(' ');
  const keywords = new Set( ["for","and", "nor", "but", "or", "yet", "so", "if", "although", "though", "after", "before", "because", "as if", "unless", "until", "when", "while"]);
   return words?.map(w => {
      const newWord = w[0] ? (w[0].toUpperCase() + w.slice(1)) : ''
      return  keywords.has(w) ? w : newWord;
  }).join(' ');
},[])
    const numberSeperator = (num) => {
        return num && num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    }
    const handleScroll = useCallback((ref) => {
      window.scrollTo({
        top: ref.offsetTop,
        left: 0,
        behavior: "smooth",
      });
    }, []);

  
    const handleErrorForDesktopDoorDelivery = (fieldValue, allValue) => {
        validateForDestopDoorDelivery(fieldValue, patterns[allValue])
    }
    const handleErrorForDesktopPickup = (fieldValue, allValue) => {
        validateForDestopPickup(fieldValue, patterns[allValue])
    }
    const handleErrorForMobileDoorDelivery = (fieldValue, allValue) => {
        ValidateForMobileDoorDelivery(fieldValue, patterns[allValue])
    }
    const handleErrorForMobilePickup = (fieldValue, allValue) => {
        ValidateForMobilePickup(fieldValue, patterns[allValue])
    }
    function genPassword(len) {
      const myArray = new Uint32Array(len);
      let password = "";
      window.crypto.getRandomValues(myArray);
      
      for (const num of myArray) {
        password += "alpha" + num
      }
         return password;
     }
     function isEmpty(obj) {
        return Object.keys(obj).length === 0;
    }
    const copyToClipBoard = async copyMe => {
        try {
            if(navigator?.clipboard?.writeText){
                await navigator.clipboard.writeText(copyMe);
            }
          setCopySuccess('Copied!');
          setCopied(true)
          setTimeout(() => {
             setCopySuccess("")
             setCopied(false)
          }, 500)
        } catch (err) {
          setCopySuccess('Failed to copy!');
          setTimeout(() => {
            setCopySuccess("");
            setCopied(false)
         }, 500)
        }
      };
      const shareOnSocialMedia = async (url, text) => {
        try {
            await navigator.share({
                title: document.title,
                url : url,
                text : text});
          
          } catch (err) {
            console.error("Share failed:", err.message);
          }
    }

    const appMemoization = useMemo(() => ({numberSeperator, mobileCheckoutModal, setMobileCheckoutModal, phoneDoorDeliveryRef, emailDoorDeliveryRef, phonePickupRef, emailPickupRef, handleErrorForDesktopDoorDelivery, handleErrorForDesktopPickup, handleErrorForMobileDoorDelivery, handleErrorForMobilePickup, mobileeDoorDeliveryPhoneRef, mobileeDoorDeliveryEmailRef, mobilePickupPhoneRef, mobilePickupEmailRef, genPassword,scrollToSection,about,faq,price, isEmpty,page,setPage,copyToClipBoard,copySuccess,copied,openShareModal, setOpenShareModal,socialMediaShareOpen,setSocialMediaShareOpen, shareOnSocialMedia,slugify,removeSlug,normalizeCase, itemLimit, setItemLimit,pageNumber, setPageNumber,openDownload, setOpenDownload,categoryName, setcategoryName, resetPasswordOtp, setResetPasswordOtp,currentUrl, setCurrentUrl,handleScroll,mobileDeliveryMethodRef}), [
      mobileCheckoutModal, setMobileCheckoutModal,copySuccess,copied,openShareModal, setOpenShareModal,socialMediaShareOpen,setSocialMediaShareOpen,page,setPage,about,faq,price,slugify,removeSlug,normalizeCase, itemLimit, setItemLimit,pageNumber, setPageNumber,openDownload, setOpenDownload,categoryName, setcategoryName, resetPasswordOtp, setResetPasswordOtp,currentUrl, setCurrentUrl,handleScroll,mobileDeliveryMethodRef
    ])
    const {Provider} = AppContext
  return <Provider value={appMemoization}>
      {children}
  </Provider>;
};
export default AppContextProvider