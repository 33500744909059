import {createSlice} from "@reduxjs/toolkit";
import CryptoJS from 'crypto-js';

const initialState = {
     first_name: "",
     last_name: "",
     email: "",
     phone: "",
     token: "",
     _id: ""
}

export const authSlice = createSlice({
     name: "auth",
     initialState,
    reducers: {
         setAlphaUser: (state, action) => {
              const {payload} = action
              let data = {first_name: payload?.first_name, last_name: payload?.last_name, email: payload?.email, phone: payload?.phone, token: payload?.token, _id: payload?._id};
              state.first_name = payload?.first_name;
              state.last_name = payload?.last_name;
              state.email = payload?.email;
              state.phone = payload?.phone;
              state.token = payload?.token;
              state._id = payload?._id;
              let encryptJson = CryptoJS.AES.encrypt(JSON.stringify(data), process.env.REACT_APP_SKEY).toString()
              let encryptedData = CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(encryptJson))
             localStorage.setItem("alpha", encryptedData);
    
          
         },
         logout: (state) => {
          localStorage.removeItem("alpha");
          state.first_name = "";
          state.last_name = "";
          state.email = "";
          state.phone = "";
          state.token = "";
          state._id = ""
         }

    }
});
export const {setAlphaUser, logout} = authSlice.actions;
export default authSlice.reducer