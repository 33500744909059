import React from 'react';
import defaultImg from "../../../assets/store/default-store-img.jpg"
import ActionNav from './ActionNav';
import CategorySection from './CategorySection';
import StoreCard from './StoreCard';
import { Upload } from 'lucide-react';


import "./merchant-store.css";
import { Link } from 'react-router-dom';

const MerchantStore = () => {
  return (
    <section className='store-container'>
        <div className='store-header'>
             <div className='header-content'>
                  <img src={defaultImg} className='store-image' alt="store"/>
                  <div className='store-title'>
                       <h1>Kicks of Lagos</h1>
                       <p>Your no 1 sneaker plug and store in the city of Lagos. Get the latest sneaker for your hype beast collection</p>
                  </div>
             </div>
        </div>
        <div className='store-content'>
             <ActionNav />
             <CategorySection />
             <StoreCard />
        </div>
        <Link to="/merchant/store/upload" className='upload-btn' >
           <Upload size={18}/>
           <span>upload</span>
        </Link>
    </section>
  
  )
}

export default MerchantStore