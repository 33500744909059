export const data = [
    {
        id: 1,
        img: "/assets/images/merchant/test.jpeg",
        name: "Off-White Odsy-1000 Low-Top",
        price: "1,320,300"
    },
    {
        id: 2,
        img: "/assets/images/merchant/test.jpeg",
        name: "Off-White Odsy-1000 Low-Top",
        price: "1,320,300"
    },
    {
        id: 3,
        img: "/assets/images/merchant/test.jpeg",
        name: "Off-White Odsy-1000 Low-Top",
        price: "1,320,300"
    },
    {
        id: 4,
        img: "/assets/images/merchant/test.jpeg",
        name: "Off-White Odsy-1000 Low-Top black white",
        price: "1,320,300"
    },
    {
        id: 5,
        img: "/assets/images/merchant/test.jpeg",
        name: "Off-White Odsy-1000 Low-Top white color",
        price: "1,320,300"
    },
    {
        id: 6,
        img: "/assets/images/merchant/test.jpeg",
        name: "Off-White Odsy-1000 Low-Top",
        price: "1,320,300"
    },
    {
        id: 7,
        img: "/assets/images/merchant/test.jpeg",
        name: "Off-White Odsy-1000 Low-Top testing all this",
        price: "1,320,300"
    },
    {
        id: 8,
        img: "/assets/images/merchant/test.jpeg",
        name: "Off-White Odsy-1000 Low-Top",
        price: "1,320,300"
    },
    {
        id: 9,
        img: "/assets/images/merchant/test.jpeg",
        name: "Off-White Odsy-1000 Low-Top",
        price: "1,320,300"
    },
    {
        id: 10,
        img: "/assets/images/merchant/test.jpeg",
        name: "Off-White Odsy-1000 Low-Top",
        price: "1,320,300"
    },
    {
        id: 11,
        img: "/assets/images/merchant/test.jpeg",
        name: "Off-White Odsy-1000 Low-Top",
        price: "1,320,300"
    }
]