import React, { useEffect, useState, useRef, useCallback, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useLocation} from "react-router-dom";
import { GetStore } from '../../../../Actions/GetStore';
import Loader from '../../../../utils/Loader';
import { ListCategories } from '../../../../Actions/Category';
import {v4 as uuid} from "uuid"
import {BsChevronLeft, BsChevronRight} from "react-icons/bs";
import {MdOutlineRotateRight} from "react-icons/md"
import { Swiper, SwiperSlide } from 'swiper/react';
import { categorySliderSettings} from '../../../../utils/common';
import { DisplayContext } from '../../../../AppContexts/DisplayContext';
import "../store.css";
import GiglCard from './GiglCard';
import GiglStoreNavbar from './GiglStoreNavbar';
import { useAuctionProductsQuery } from '../../../../Actions/Auction/Auction';
import PageWrap from '../../PageWrap/PageWrap';

const Giglsales = () => {
    const {setIsAlphaStore} = useContext(DisplayContext);
    const {store} = useSelector(state => state.GetStoreReducer);
    const {category_error, list_categories,category_loading} = useSelector(state => state.allCategories);
    const [activeItem, setActiveItem] = useState("All Products");
    const [current, setCurrent] = useState(0);
    const [itemPerPage,setItemPerPage] = useState(1);
    const {isLoading, isError, error, data} = useAuctionProductsQuery();
    const errorMessage = isError && error && error.hasOwnProperty("data") ? JSON.stringify(error.data) : JSON.stringify(error?.error);
    const dispatch = useDispatch(null);
    const [storeData, setStoreData] = useState([]);
    const storeItems = data && data.results && data.results.docs
    const slider = useRef(null);
    const {slug} = useParams();
    const name = store && store.results && store.results.name;
    const description = store && store.results && store.results.description;
    const storePicture = store && store.results && store.results.image_url 
    const lastIndex = storeData && storeData?.length
    const currentData = storeData && storeData.slice(0,itemPerPage);
    const currentLength = currentData && currentData?.length;
    const {pathname} = useLocation();
    const nameToLowerCase = name && name.toLowerCase();
    const handleChange = (e) => {
        setActiveItem(e.target.value)
    }

    const modifyCategory = list_categories && list_categories.map(({name, _id}) => {
        return {_id,name}
    });
    const newCategoryory = [{_id: uuid(8), name: "All Products"}, ...modifyCategory]

    const filteredProduct =
    storeItems &&
    storeItems.filter((product) =>
      product.productName.toLowerCase().includes(activeItem.toLowerCase()) || product.categoryName.toLowerCase().includes(activeItem.toLowerCase())
    );
  const dataToDisplay = activeItem !== "All Products" ? filteredProduct : activeItem === "All Products" ? currentData : filteredProduct;
  const currentActiveIndex = useCallback(() => {
    setCurrent(slider?.current?.activeIndex)
  }, []);
  const loadMore = useCallback(() => {
       if(lastIndex === currentLength){
          return;
       }else {
             setItemPerPage(prev => prev + 10)
       }
  }, [currentLength, lastIndex])
    useEffect(() => {
         dispatch(GetStore(slug))
    }, [dispatch, slug]);
    useEffect(() => {
        if(storeItems?.length > 0){
            setStoreData(storeItems)
        }
    }, [storeItems]);
    useEffect(() => {
        dispatch(ListCategories())
    }, [dispatch]);
    useEffect(() => {
        if((pathname.includes("stores/alpha"))){
            setIsAlphaStore(nameToLowerCase || "gigl sales") 
        }
     
    }, [nameToLowerCase, setIsAlphaStore, pathname]);
    useEffect(() => {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
      }, [slug]);
    return (
       <PageWrap>
          <>
         <GiglStoreNavbar storeImg={storePicture} storeName={name}/>
        <div>
          
           {
             isError ? <p className='text-center text-sm login__error-msg'>{error?.hasOwnProperty("data") ? JSON.parse(errorMessage)["message"] : JSON.parse(errorMessage)}</p> :
            isLoading ?        
                <div className="tracking-loading">                           
                    <Loader />
                </div> : 
               
                
                <div>
                    <section className="store_section">
                        
                        <div className="store_owner_details">
                            <div className="store__details">
                                <h3>{name}</h3>
                            </div>
                        
                            <p className="store__description">
                                {description}
                            </p>
                        </div>
                    </section>
                    <section className="delivery_info">
                                <div className="services-desc"><img src="/fast-delivery.png" alt="fast-delivery"/><div className="service-rendered">Fast Delivery</div></div>
                                <div className="services-desc"><img src="/customer-care.png" alt="fast-delivery"/><div className="service-rendered">Customer Support</div></div>
                                <div className="services-desc"><img src="/seamless-payment.png" alt="fast-delivery"/><div className="service-rendered">Seamless payment</div></div>
                                <div className="services-desc"><img src="/best-price.png" alt="fast-delivery"/><div className="service-rendered">Best Prices</div></div>
                    </section>
                    <section className="prod__from-store">
                          
                        <div className='products__instore-label'>Products from this Store</div>
                      
                              
                            <div className='all-categories'>
                           
                               {category_error && <p className="text-center text-danger">{category_error}</p>}
                               {category_loading && <p>fetching categories...</p>}
                               {
                                   list_categories && list_categories?.length > 0 && 
                                   
                                   <div className='product_categories'>
                                     <div className='list-categories'>Categories</div>
                                     <div className='products__list-category'>
                                     
                                     
                                        <Swiper  {...categorySliderSettings} className='category__slide-snap' onSlideChange={currentActiveIndex} onSwiper={(it) => {(slider.current = it); setCurrent(it?.activeIndex)}}>
                                            {
                                                current !== 0 &&  <button onClick={() => {slider?.current?.slidePrev()}} className='prev-btn'><BsChevronLeft /> </button>
                                            }
                                       
                                            <div>
                                        
                                            {
                                                newCategoryory && newCategoryory?.map((category) => (
                                                    <SwiperSlide key={category._id} title={category.name} className={`category-name ${activeItem === category.name? "alpha-red" : ""}`} style={{cursor: "pointer"}} onClick={() => setActiveItem(category.name)}>
                                                    {category.name}   
                                                    </SwiperSlide> 
                                        
                                                ))
                                            }
                                           
                                            </div>
                                            {
                                                 current !== newCategoryory?.length-4 && <button onClick={() => {slider?.current?.slideNext()}} className='next-btn'><BsChevronRight/></button>
                                            }
                                        
                                        </Swiper>
                                        
                                       </div>
                                       </div>
                                    
                                }
                            </div>
                            <div className='search__category-mobile'>
                                <div className='category-label'>Sort by Categories</div>
                                <div  className='categories'>
                                    <select onChange={handleChange} value={activeItem}>
                                        {
                                            newCategoryory && newCategoryory.map(({name, _id}) => (
                                           
                                                <option value={name} className='category-name' style={{ maxWidth: "120px",fontSize: "12px"}} key={_id}>{name?.length > 25 ? name.substring(0,25) + "..." : name}</option>     
                                    
                                            ))
                                        }
                                    </select>
                                </div>
                            </div>
                     
                    </section>
                    {dataToDisplay && dataToDisplay?.length > 0 ? 
                          <section className="store_products store_products-mt">
                
                            
                              <div className="row mt-4">
                                
                                    {dataToDisplay && dataToDisplay.map((product) => (
                                      
                                        <GiglCard product={product} key={`${product._id}_store`}/>
                                    ))}
                                   
                              </div>
                           
                         
                        
                      </section>: <div className="unavailable-product">Item is not available on this store</div>     
                    }
                    {
                        lastIndex === currentLength || dataToDisplay?.length === 0  ? <div className='nomore__show-more'>No more Items to show</div> : <button className='store__show-more' onClick={loadMore}><span className={`${isLoading && "showmore-rotate"}`}><MdOutlineRotateRight size={18} /></span>Show More</button>
                    }
                 
            </div>
          
            }


           
        </div>
          </>
       </PageWrap>
    )
}
export default Giglsales;