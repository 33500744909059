import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import { getUrl } from "../../helpers/getUrl";
import {config} from "../../config";

export const contactApi = createApi({
        reducerPath: "contactApi",
        baseQuery: fetchBaseQuery({baseUrl:`${getUrl(config.prodUserUrl,config.devUserUrl)}`}),
        tagTypes: ["Contact"],
        endpoints: (builder) => ({
            newContact: builder.mutation({
                query: body => ({
                    url: "/contacts/create",
                    method: "post",
                    body
                }),
                invalidatesTags: ["Contact"]
            }),
        })
});
export const {useNewContactMutation} = contactApi