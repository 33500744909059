import React from 'react';
import { data } from './data';
import {MdOutlineRotateRight} from "react-icons/md"

const StoreCard = () => {
  return (
    <div>
        <div className='store-card my-4'>
            {
                data.map((product) => (
                    <div key={product.id} className='card-details'>
                        <img src={product.img} alt={product.name}/>
                        <div className='price-description'>
                            <div className='text-center' title={product.name}>{product.name}</div>
                            <h3 className='text-center'>N {product.price}</h3>
                        </div>
                    </div>
                ))
            }
                {/* {
                            lastIndex === currentLength || dataToDisplay?.length === 0  ? <div className='nomore__show-more'>No more Items to show</div> : 
                            <button className='store__show-more' onClick={loadMore}><span className={`${isLoading && "showmore-rotate"}`}><MdOutlineRotateRight size={18} /></span>Show More</button>
                        } */}
        
        </div>
        <button className='store__show-more storecard'><span className={``}><MdOutlineRotateRight size={18} /></span>Show More</button>
    </div>
  )
}

export default StoreCard