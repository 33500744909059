import {FaFacebookF,FaInstagram, FaTwitter, FaLinkedinIn} from "react-icons/fa";
import Socials from "./MobileDevice/Socials";
import { Link } from "react-router-dom/dist";

const Footer = () => {
    
    return (
        <footer className="footer-bar">
           <Socials />
            <hr className="footer-hr"/>

            <div className="footer-container">
                <div className="social-icons-desktop">
                        <div  className="icon-social">
                            <a href="https://www.facebook.com/ShoponAlpha" target="_blank" rel="noreferrer"><FaFacebookF/></a>
                        </div>
                        <div  className="icon-social">
                            <a href="https://www.linkedin.com/company/shoponalpha/" target="_blank" rel="noreferrer"><FaLinkedinIn/></a>
                        </div>
                        <div  className="icon-social">
                            <a href="https://twitter.com/ShoponAlpha" target="_blank" rel="noreferrer"><FaTwitter/></a>
                        </div>
                        <div  className="icon-social">
                           <a href="https://www.instagram.com/shoponalpha/" target="_blank" rel="noreferrer"><FaInstagram/></a>
                        </div>  
                </div>
                <div className="text-left footer-policy">
                    <Link to='terms&conditions'>Terms and conditions</Link>
                    <Link to='privacy-policy'>Privacy policy</Link>
                </div>
                <div className="text-center footer-snippet">
                     &copy;{new Date().getFullYear()} Alpha. All rights reserved.
                </div>
            </div>
        </footer>
    )
}

export default Footer