import React from 'react'
import ListSizes from './ListSizes';

const DescriptionForm = () => {
   
  return (
    <section className='d-flex flex-column gap-2 upload-part-form'>
        <div className='d-flex flex-column gap-1 w-100 position-relative'>
            <label>Enter Shoe name</label>
            <input type='text' placeholder='Nike New Balance'  className='w-100 px-2'/>
        </div>
        <div>
            <label>Available Sizes (You can select more than one)</label>
            <>
                <ListSizes />
            </>
        
        </div>
        
    </section>
  )
}

export default DescriptionForm