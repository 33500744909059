import {LOCAL_STATIONS_GET_FAIL, LOCAL_STATIONS_GET_REQUEST, LOCAL_STATIONS_GET_SUCCESS } from "../constants/constants";
import { getUrl } from "../helpers/getUrl";

export function localStation () {
      return async (dispatch) => {
          dispatch({type: LOCAL_STATIONS_GET_REQUEST});
            try {
              const res = await fetch(`${getUrl(process.env.REACT_APP_ORDER_BASE_URL_PRODUCTION,process.env.REACT_APP_ORDER_BASE_URL)}/order/guest/stations`, {
                   method: "GET",
                   headers: {
                       "Content-Type": "application/json",
                       ACCEPT: "application/json"
                   }
              })
              const data = await res.json();
              if(data.error){
                    return dispatch({type: LOCAL_STATIONS_GET_FAIL, payload: data.message})
              }
              dispatch({type: LOCAL_STATIONS_GET_SUCCESS, payload: data})    
            } catch (error) {
            const message =
            error.response && error.response.data
              ? error.response.data.message
              : error.message;
              dispatch({type: LOCAL_STATIONS_GET_FAIL, payload: message})
            }
    }
}