import { useContext} from 'react';
import { useDispatch} from 'react-redux';
import { FaCheck } from 'react-icons/fa';
import {MdArrowBack} from "react-icons/md";
import { DisplayContext } from "../../../AppContexts/DisplayContext";
import { Product } from '../../../Actions/Product';
import "../MobileDevice/mobile.css";
import { AppContext } from '../../../AppContexts/AppContext';
import { usePriceByQtyQuery } from '../../../Actions/Price/PriceByQuantity';
const CheckoutModal = ({itemSize, isActiveSize, colorVariation, borderVariation, isActiveColor, newColor, availableColor, color,categorySize, id, validateOrderCriteria, setValidateError,validateError,isAddToCartLoading,validateShipNowCriteria,newPrice}) => {
    const {setSize, setStyleSize, setItemColor, setStyleColor, borderColor, setBorderColor, setColor, itemColor, qty, setQty, handleMobileMenuSwitch, showMobileDeliveryOption, initialQty, setInitialQty,setIsSelectColor,setIsSelectSize,isSelectedSize, sizeObj, setSizeObj} = useContext(DisplayContext); 
    const {numberSeperator,mobileDeliveryMethodRef, handleScroll} = useContext(AppContext);
    const dispatch = useDispatch(null);
    const getUrlVal = window.location.href;
    const afterLastSlash = getUrlVal.substring(getUrlVal.lastIndexOf('/') + 1);
    const sizeCheck = itemSize && itemSize[0] && itemSize[0].value;
    const {data} = usePriceByQtyQuery({id, qty});
    // const isPromoPrice = isPromoting && qty > 1 ? data?.results : discountedPrice;
    // const newPrice = isPromoting ? isPromoPrice  : data?.results;
   const getSizeQty = sizeObj.quantity
    const itemPrice = qty > 1 ? data?.results : newPrice
    return (
        <div className={showMobileDeliveryOption === 1 ? "mobile-checkout-screen" : "hide-checkout-modal"}>
             <div className="available-sizes available-sizes-mobile">
                   <div className="my-4">
                            {itemSize && itemSize.length === 0 && categorySize && <p className="sizes-home" onClick={() => {handleMobileMenuSwitch(0);dispatch(Product(afterLastSlash)); setSizeObj({})}}><MdArrowBack className="back-to-home text-dark"/>Back</p>}
                            {categorySize && itemSize && itemSize.length > 0 && itemSize[0].value !== "" && 
                            <>

                                {categorySize && itemSize[0].value !== "" &&  <p className="sizes" onClick={() => {handleMobileMenuSwitch(0);dispatch(Product(afterLastSlash))}}><span><MdArrowBack className="back-to-home"/></span>Sizes available</p>}
                                <div className= "sizes-select">
                                    {
                                   categorySize &&  itemSize?.length > 0 && itemSize.map((size, index) => {
                                        return size.value !== "" &&
                                        <button key={"size-" + size._id} className={isActiveSize(index)} onClick={() => {setSize(size.value); setSizeObj(size); setStyleSize(index); setBorderColor(null); setItemColor(size.color); setInitialQty(size.quantity); setValidateError(""); setIsSelectSize(true); setIsSelectColor(false); setQty(1); setColor("")}}>{size.value}</button>
                                    })  
                                    }
                                </div>
                            
                            </>
                            }
                                        
                    </div>
             </div>
             <div className="available-colors mobile-color-select">
                        {itemColor && itemSize?.length > 0 && isSelectedSize &&
                                        <div className="prefered-color">
                                            
                                            <p className="prefer">choose preferred color</p>
                                            <div className="color-picker">
                                            {
                                               itemColor && newColor.map((color, index) => (
                                                    <button className={`${borderColor === index ? borderVariation(color) : ""}`} key={"bg-" + color} style={{backgroundColor: color}}  onClick={() => {setColor(color); setStyleColor(index); setValidateError(""); setBorderColor(index); setIsSelectColor(true)}} ><FaCheck className={`${colorVariation(color)}  ${isActiveColor(index)}`}/></button>
                                                ))
                                            }
                                            </div>
                                        
                                        </div>
                                    }
                                    {
                                        availableColor && !sizeCheck &&
                                            <div className="prefered-color">
                                              <p className="prefer">choose preferred color</p>
                                                   <div className="color-picker">
                                                   {
                                                        availableColor.includes(",") ? availableColor.split(",").map((newColor, index) => (
                                                            <button className={`${borderColor === index ? borderVariation(newColor) : ""}`} key={newColor} style={{backgroundColor: newColor}}  onClick={() => {setColor(newColor); setStyleColor(index); setBorderColor(index); setIsSelectColor(true); setValidateError("")}} ><FaCheck className={`${colorVariation(newColor)}  ${isActiveColor(index)}`}/></button>
                                                        )) :
                                                        <button className={`${borderColor === 1 ? borderVariation(color) : ""}`} style={{backgroundColor: availableColor}}  onClick={() => {setColor(availableColor); setStyleColor(1); setIsSelectColor(true); setValidateError(""); setBorderColor(1)}} ><FaCheck className={`${colorVariation(color)} ${isActiveColor(1)}`}/></button>
                                                    }
                                                    </div>
                                            </div>
                                    }
            
             </div>
             <hr />
             <div className="alpha-price-section">
                 <h2>Total Price</h2>
                 <h1>&#8358; {numberSeperator(itemPrice)}</h1>
             </div>
             <hr />
             <div className="qty-btn-section-phone"> 
              <button className={qty <= 1 ? "btn-minus-mobile item-add-disabled-mobile" : "btn-minus-mobile"} onClick={() => {setQty(prev => prev - 1); setValidateError("")}} disabled={qty <= 1}>-</button>
              <button className="btn-qty-mobile">{qty}</button>
              <button className={qty === initialQty  ? "btn-plus-mobile item-add-disabled-mobile" : "btn-plus-mobile"} onClick={() => {setQty(prev => prev + 1); setValidateError("")}} disabled={qty === initialQty}>+</button></div>
              {validateError && <div className="text-danger text-center">{validateError}</div>}
              {
                 getSizeQty === 0 ? <div className='text-center text-xl text-danger d-flex justify-content-center align-items-center w-100 font-weight-bold'>Out of stock</div> : <>
                 <div className="phone-buynow-btn" onClick={() => {validateShipNowCriteria(); handleScroll(mobileDeliveryMethodRef?.current)}}>
                         <button disabled={getSizeQty === 0}>Pay and ship now</button>
                 </div>
                 <div className={isAddToCartLoading ? "phone-checkout-btn checkout-btn-disabled mt-2" : "phone-checkout-btn mt-2"} onClick={() =>  validateOrderCriteria()}>
                         <button disabled={isAddToCartLoading || getSizeQty === 0}>{isAddToCartLoading ? "Adding to cart..." : "Add to cart"}</button>
                 </div>
               </>
              }
              
              
        </div>
        )
}


export default CheckoutModal;