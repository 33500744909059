import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import { getUrl } from "../../helpers/getUrl";
import {config} from "../../config";

export const authApi = createApi({
        reducerPath: "authApi",
        baseQuery: fetchBaseQuery({baseUrl: `${getUrl(config.prodUserUrl,config.devUserUrl)}`,  prepareHeaders: (headers, { getState }) => {
            const token = getState().auth.token
        
            // If we have a token set in state, let's assume that we should be passing it.
            if (token) {
              headers.set('authorization', `Bearer ${token}`)
            }
        
            return headers
          }}),
        endpoints: (builder) => ({
            loginUser: builder.mutation({
                query: data => ({
                    url: "/auth/login",
                    method: "post",
                    body: data
                })
            }),
            registerUser: builder.mutation({
                query: data => ({
                    url: "/user/guest/new",
                    method: "post",
                    body: data
                })
            }),
            forgotPassword: builder.mutation({
                query: data => ({
                    url: "/auth/forgot_password",
                    method: "post",
                    body: data
                })
            }),
            verifyOtp: builder.mutation({
                query: data => ({
                    url: "/auth/verify_otp",
                    method: "post",
                    body: data
                })
            }),
            resetPassword: builder.mutation({
                query: data => ({
                    url: "/auth/reset_password",
                    method: "post",
                    body: data
                })
            }),
            deleteUserAccount: builder.mutation({
                query: email => ({
                    url: `/user/delete/by_email/${email}`,
                    method: "delete"
                })
            })
        })
});
export const {useLoginUserMutation, useRegisterUserMutation, useForgotPasswordMutation, useResetPasswordMutation, useVerifyOtpMutation, useDeleteUserAccountMutation} = authApi;