import React, { useContext } from 'react'
import { Link } from 'react-router-dom';
import { AppContext } from '../../../AppContexts/AppContext';


const ActionNav = () => {
  const {copyToClipBoard,copySuccess,copied} = useContext(AppContext)
  return (
    <div className='action-nav'>
        <Link to="/merchant/store/upload" className='upload-action action'>
           <img src="/assets/images/merchant/upload-icon.svg" alt='upload'/>
           <span>Upload Product(s)</span>
          
        </Link>
        <Link to="/merchant/store/boost" className='boost-action action'>
           <img src="/assets/images/merchant/boost-icon.svg" alt='upload'/>
           <span>Boost your Store</span>
          
        </Link>
        <Link to="/merchant/store/discount" className='discount-action action'>
           <img src="/assets/images/merchant/discount-icon.svg" alt='upload'/>
           <span>Create Discount(s)</span>
          
        </Link>
        {copied ? (<span className="copied-text">{copySuccess}</span>) : 
        <div  className='copy-action action' onClick={() => copyToClipBoard("Test copy")}>
           <img src="/assets/images/merchant/link-icon.svg" alt='upload'/>
           <span>Copy Store link</span>
        </div> }
    </div>
  )
}

export default ActionNav