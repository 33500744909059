import UploadCategories from "./UploadCategories"
import UploadSection from "./UploadSection"
import "./upload.css"

const Upload = () => {
    return (
      <article className="upload-section">
           <div className="upload-header">
               <div>
                   <div>Upload Product</div>
                   <div><div className="green-dot"/> Bulk upload (2)</div>
               </div>
               <button type="buttone">Next</button>
           </div>
           <div className="upload-categories">
                <div>
                    <div className="green-dot_big"/>
                    <span>Choose Category</span>
                </div>
                <div>
                    <UploadCategories />
                </div>
           
                
           </div>
           <div>
              <UploadSection />
           </div>
      </article>
    )
}

export default Upload