import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import { getUrl } from "../../helpers/getUrl";

export const orderApi = createApi({
    reducerPath: 'orderApi',
    baseQuery: fetchBaseQuery({baseUrl:`${getUrl(process.env.REACT_APP_ORDER_BASE_URL_PRODUCTION,process.env.REACT_APP_ORDER_BASE_URL)}`,  prepareHeaders: (headers, { getState }) => {
        const token = getState().auth.token
    
        // If we have a token set in state, let's assume that we should be passing it.
        if (token) {
          headers.set('authorization', `Bearer ${token}`)
        }
    
        return headers
      }}),
    tagTypes: ['order'],
    endpoints: (builder) => ({
        getRecentOrders: builder.query({
            query: ({buyerID, page, limit})=>({
                url: `order/buyer_orders?buyer_id=${buyerID}&page=${page}&limit=${limit}`,
                method: 'GET'
            }), 
            providesTags: ['order']
        }),
        addAddress: builder.mutation({
            query: (data)=>({
                url: `address/new`,
                method: 'POST',
                body: data
            }), 
            invalidatesTags: ['order']
        }),
        deleteAddress: builder.mutation({
            query: ({addressId})=>({
                url: `address/delete?addressId=${addressId}`,
                method: 'DELETE',
            }), 
            invalidatesTags: ['order']
        }),
        getAddressList: builder.query({
            query: ({userId})=>({
                url: `address?userId=${userId}`,
                method: 'GET'
            }), 
            providesTags: ['order']
        }),
        getServiceCenter: builder.query({
            query: ({id})=>({
                url: `/order/guest/service_centers?stationId=${id}`,
                method: 'GET'
            }), 
            providesTags: ['order']
        })
    })
})

export const {useGetRecentOrdersQuery, useAddAddressMutation, useGetAddressListQuery, useDeleteAddressMutation, useGetServiceCenterQuery} = orderApi