import React, { useState } from 'react'
import UploadForm from './UploadForm';
import UploadDescriptionForm from './UploadDescriptionForm';
import ImagesVideosUpload from './ImagesVideosUpload';
import UploadImageForm from './UploadImageForm';
import DescriptionForm from './DescriptionForm';
import { ChevronLeft, ChevronRight } from 'lucide-react';

const MobileImageUploadSection = () => {
  const [goTo, setGoTo] = useState(1);
  const handleGoTo = (action) => {
       if(action === "next" && goTo < 3){
          setGoTo(prev => prev + 1)
       }else if(action === "prev" && goTo > 1){
          setGoTo(prev => prev -1)
       }else {
           return null;
       }
  }
  return (
    <div className='d-block d-md-none  mobile-upload-section'>
           <div className='d-flex flex-column gap-4 align-items-center py-4'>
             <div className='w-full'>
                  {
                     goTo === 1 && <div className="mobile-upload-media d-flex flex-column gap-2 ">
                        <UploadImageForm />
                        <ImagesVideosUpload />
                      </div>
                  }
                  {
                     goTo > 1 && (
                      <div className='d-flex flex-column  mobile-product-description gap-2'>
                      {
                        (goTo === 2 || goTo === 3) && <UploadDescriptionForm />

                      }
                      {
                            goTo === 2 && (<div>
                                  <UploadForm />
                            </div>)
                        }
                          {
                             goTo === 3 && (
                                <div>
                                   <DescriptionForm />
                                </div>
                             )
                        }
                  </div>
                     )
                  }
                  
             </div>
             <div className='d-flex align-items-center gap-2'>
                  
                 <button onClick={() => handleGoTo("prev")} disabled={goTo === 1} className={goTo === 1 ? "disable-goto goto-btn" : "goto-btn"}><ChevronLeft /> Prev</button>
                 <button onClick={() => handleGoTo("next")} disabled={goTo === 3} className={goTo === 3 ? "disable-goto goto-btn" : "goto-btn"}>Next <ChevronRight /></button>
             </div>
         </div>
    </div>
  )
}

export default MobileImageUploadSection