import CryptoJS from "crypto-js";

export const decryptData = (encryptedData, secretKey) => {
    if(encryptedData && secretKey){
        let decData = CryptoJS.enc.Base64.parse(encryptedData).toString(CryptoJS.enc.Utf8)
        let bytes = CryptoJS.AES.decrypt(decData, secretKey).toString(CryptoJS.enc.Utf8)
        return JSON.parse(bytes)
    }

   
};
