import React, {useState, useRef, useCallback, useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux';
import {v4 as uuid} from "uuid"
import { ListCategories } from '../../../Actions/Category';



const CategorySection = () => {
    const slider = useRef(null);
    const dispatch = useDispatch()
    const {category_error, list_categories,category_loading} = useSelector(state => state.allCategories);
    const [activeItem, setActiveItem] = useState("All Products");
    const [current, setCurrent] = useState(0);

    const handleChange = (e) => {
        setActiveItem(e.target.value)
    }
    const modifyCategory = list_categories && list_categories.map(({name, _id}) => {
        return {_id,name}
    });
    const newCategoryory = [{_id: uuid(8), name: "All Products"}, ...modifyCategory]
    const currentActiveIndex = useCallback(() => {
        setCurrent(slider?.current?.activeIndex)
      }, []);

    useEffect(() => {
        dispatch(ListCategories())
    }, [dispatch]);
  return (
      <div className='mt-2 store-category'>
                           
            {category_error && <p className="text-center text-danger">{category_error}</p>}
            {category_loading && <p>fetching categories...</p>}
            {
                list_categories && list_categories?.length > 0 && 
                
                <div className='list-category'>
                       <div className='cat-text'>Categories</div>  
                       <div className='all-category'>
                            {
                                newCategoryory && newCategoryory?.map((category) => (
                                    <div key={category._id} title={category.name} className={`category-name ${activeItem === category.name? "alpha-red active-category" : ""}`} style={{cursor: "pointer"}} onClick={() => setActiveItem(category.name)}>
                                    {category.name}   
                                    </div> 
                                ))
                            }
                        </div>
                    </div>
                
            }
                    
    </div>
  )
}

export default CategorySection