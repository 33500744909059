import React, {useState} from 'react'
import { Search } from 'lucide-react';
import Switch from "react-switch";


const UploadForm = () => {
    const [checked, setCheck] = useState(false);
    const handleCheck = () => {
        setCheck(current => !current)
    }
  return (
    <section className='d-flex flex-column gap-2 upload-part-form'>
        <div className='d-flex flex-column gap-1 w-100'>
            <label>Shoe type</label>
           <select className='w-100'>
               <option>one</option>
               <option>two</option>
               <option>three</option>
               <option>four</option>
           </select>
        </div>
        <div className='d-flex flex-column gap-1 w-100'>
            <label>Description</label>
             <textarea placeholder='Enter product description'/>
        </div>
        <div className='custom-divider'/>
        <div className='d-flex flex-column gap-1 w-100 position-relative'>
            <label>Enter Pickup Location</label>
            <input type='text' placeholder='102 Agbara Road, Badagry 110293'  className='w-100 px-2'/>
            <Search size={24} className='search__icon'/>
        </div>
        <div className='d-flex flex-column gap-1 w-100 position-relative'>
            <label>Product Weight (kg)</label>
            <input type='number' placeholder='Enter weight of the product'  className='w-100 px-2'/>
        </div>
        <div className='d-flex flex-column gap-1 w-100'>
            <label>Choose required pickup vehicle type</label>
           <select className='w-100'>
               <option>one</option>
               <option>two</option>
               <option>three</option>
               <option>four</option>
           </select>
        </div>
        <div className='discount-apply'>
            <div>
                <h3>Apply Discount</h3>
                <p>Increase your sales for this product by creating an attractive discount.</p>
            </div>
            <Switch 
                onChange={handleCheck}
                checked={checked} 
                offColor="#d3d3d3" 
                onColor="#192150"
                uncheckedIcon={false}
                checkedIcon={false}
                height={21}
                width={38}
            />
        </div>
        {
            checked && <div className='w-100'>
            <input type='number' placeholder='Enter discount amount'  className='w-100 pl-2' style={{paddingRight: "0px !important", marginTop: "-1rem"}}/>
        </div>
        }
    </section>
  )
}

export default UploadForm