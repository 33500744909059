import React, {useState} from 'react'
import UploadImageForm from './UploadImageForm';
import ImagesVideosUpload from './ImagesVideosUpload';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import UploadDescriptionForm from './UploadDescriptionForm';
import UploadForm from './UploadForm';
import DescriptionForm from './DescriptionForm';

const TabletImageUploadSection = () => {
  const [goTo, setGoTo] = useState(1);
  const handleGoTo = (action) => {
       if(action === "next" && goTo < 2){
          setGoTo(2)
       }else if(action === "prev" && goTo > 1){
          setGoTo(1)
       }else {
           return null;
       }
  }
  return (
    <div className='d-none d-md-block d-lg-none tablet-upload-section'>
         <div className='d-flex flex-column gap-4 align-items-center py-4'>
             <div>
                  {
                     goTo === 1 && <div className="tablet-upload-media d-flex flex-column gap-2 ">
                        <UploadImageForm />
                        <ImagesVideosUpload />
                      </div>
                  }
                  {
                     goTo === 2 &&    <div className='d-flex flex-column  web-product-description gap-2'>
                        <UploadDescriptionForm />
                        <div className="d-flex justify-content-between">
                            <UploadForm />
                            <DescriptionForm />
                        </div>
                    </div>
                  }
             </div>
             <div className='d-flex align-items-center gap-2'>
                  
                 <button onClick={() => handleGoTo("prev")} disabled={goTo === 1} className={goTo === 1 ? "disable-goto goto-btn" : "goto-btn"}><ChevronLeft /> Prev</button>
                 <button onClick={() => handleGoTo("next")} disabled={goTo === 2} className={goTo === 2 ? "disable-goto goto-btn" : "goto-btn"}>Next <ChevronRight /></button>
             </div>
         </div>
    </div>
  )
}

export default TabletImageUploadSection