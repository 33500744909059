export const UploadSettings = {
    slidesPerView: 1,

    breakpoints: {
        280: {
            slidesPerView: 1
        },
        360: {
            slidesPerView: 2.7,
            spaceBetween: 30,
        },
        768: {
            slidesPerView: 4.5,
        },
        
        1024: {
            slidesPerView: 6,
  
        },
        1280: {
            slidesPerView: 9
  
        },
        
     
     
    }
}