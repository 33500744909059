import { Plus } from 'lucide-react';
import React from 'react';
import { MdLinkedCamera } from "react-icons/md";

const ImagesVideosUpload = () => {
  return (
    <div className='d-flex flex-column gap-2'>
         <label htmlFor="front-img" className='front-img-vid cursor-pointer flex flex-column gap-1 justify-content-center'>
               <input type="file" id='front-img'/>
               <MdLinkedCamera size={24} color='#262641'/>
               <span className='upload-label'>(.jpg, .png - Max 18MB)</span>
               <div className='upload__vid_img-btn'>Upload photo</div>
         </label>
         <div className='other-imgs'>
            <label htmlFor="back-img">
               <input type="file" id='front-img'/>
               <Plus size={24} color='#5C5C77'/>
            </label>
            <label htmlFor="rear-img">
                <input type="file" id='front-img'/>
                <Plus size={24} color='#5C5C77'/>
            </label>
            <label htmlFor="side-img">
                <input type="file" id='front-img'/>
                <Plus size={24} color='#5C5C77'/>
            </label>
         </div>
         <label htmlFor='video-upload' className='video-upload-sec'>
               <input type='file' id='video-upload'/>
               <div className='upload__vid_img-btn'>Upload Video</div>
               <span className='upload-label'>(.mp4, .mov - Max 18MB)</span>
         </label>
    </div>
  )
}

export default ImagesVideosUpload