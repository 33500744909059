import { Plus, Check } from 'lucide-react';
import React, { useState } from 'react';
import { CirclePicker } from 'react-color';
import { uniqueColors } from './../../../../utils/colorPalette';

const ListSizes = () => {
  const [colorIndex, setColorIndex] = useState(null)
  const initialSizes = ['XS', 'S', 'M', 'L', 'XL'];
  const [selectedSizes, setSelectedSizes] = useState([]);
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [currentSizeIndex, setCurrentSizeIndex] = useState(null);

  const handleSizeClick = (size) => {
    const index = selectedSizes.findIndex((item) => item.value === size);
    if (index !== -1) {
      // If size is already selected, remove it from the selectedSizes
      const newSelectedSizes = selectedSizes.filter((item) => item.value !== size);
      setSelectedSizes(newSelectedSizes);
    } else {
      // If size is not selected, add it to the selectedSizes
      setSelectedSizes([...selectedSizes, { value: size, quantity: 0, colors: [] }]);
    }
  };

  const handleQuantityChange = (index, quantity) => {
    const newSelectedSizes = [...selectedSizes];
    newSelectedSizes[index].quantity = quantity;
    setSelectedSizes(newSelectedSizes);
  };

  const handleAddColor = (index, color) => {
    const newSelectedSizes = [...selectedSizes];
    if (!newSelectedSizes[index].colors.includes(color)) {
      newSelectedSizes[index].colors.push(color);
      setSelectedSizes(newSelectedSizes);
    }
  };
  const showColor = (index) => {
        setColorIndex(index)
  }

  const handleRemoveColor = (index, color) => {
    const newSelectedSizes = [...selectedSizes];
    newSelectedSizes[index].colors = newSelectedSizes[index].colors.filter((c) => c !== color);
    setSelectedSizes(newSelectedSizes);
  };


  const toggleColorPicker = (index) => {
    setCurrentSizeIndex(index);
    setShowColorPicker(!showColorPicker);
  };



  return (
    <div>
      <div className='d-flex my-2 gap-2 size-color-option'>
            {initialSizes.map((size) => (
                <button 
                    key={size} 
                    onClick={() => handleSizeClick(size)}
                    className={selectedSizes.some(item => item.value === size) ? "active__size-button" : "upload__size-button"}
                >
                    {size}
                </button>
            ))}
      </div>
      {

          selectedSizes[0] && <label>Stock Quantity <small>(Enter available quantity)</small></label>
         
      }

      {selectedSizes.map((item, index) => (
        <div key={index} className='mb-2'>
          <div className='color-qty-size'>
            <div className='position-relative size-input'>
              <button className='size-btn-text'>{item.value}</button>
              <input
                  type="number"
                  value={item.quantity}
                  onChange={(e) => {handleQuantityChange(index, Number(e.target.value)); showColor(index)}}
                  className='qty-input w-100'
                  placeholder='Enter available quantity'
                  min={0}
              />
            </div>
           {
              item.colors?.length > 0 ?  <div className='position-relative d-flex align-items-center '>
              {item.colors.map((color, i) => (
                <div title={"click to remove color"} key={i} style={{ backgroundColor: color, width: "20px", height: "20px", borderRadius: "50%", border: `1px solid black`, cursor: "pointer"}} className='selected-color-style' onClick={() => {handleRemoveColor(index, color)}}/>
              ))}
          </div> : null 
           }
           
            <div className='add-colors' onClick={() => toggleColorPicker(index)}><span className='add-btn-icon'><Plus size={14}/></span> {item?.colors?.length < 1  && <span className='add-text'>Add color</span>}</div>
          </div>
               <div className="my-2 color-palette-section">
       
                  {showColorPicker && currentSizeIndex === index && (
             
                      uniqueColors.map((color) => (
                        <button style={{backgroundColor: color, height: "22px", width: "22px", border: "1px solid #000000", borderRadius: "50%"}} 
                        onClick={() => {
                          if (item.colors.includes(color)) {
                            handleRemoveColor(index, color);
                          } else {
                            handleAddColor(index, color);
                          }
                        }}
                           title={item.colors.includes(color) ? "Click to remove color" : "Click to add color"}
                        >
                             {item.colors.includes(color) ? <Check size={20} color={color === "#000000" ? "#FFFFFF" : "#000000"}/> : null}
                        </button>
                      ))
                    
                    
                  )}
              </div>
            
            </div>
          ))}
    </div>
  );
};

export default ListSizes;
