import React, { useRef, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Swiper, SwiperSlide } from 'swiper/react';
import { bgImages } from '../../../../helpers/category/category';
import { ListCategories } from '../../../../Actions/Category';
import { UploadSettings } from '../Settings';
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs';
import { Check } from 'lucide-react';




const UploadCategories = () => {
    const dispatch = useDispatch();
    const slider = useRef(null);
    const [isCategoryIndex, setIsCategoryIndex] = useState(null)
    const [hasSlide, setHasSlide] = useState({
        isBeginning: true,
        isEnd: false
    })
    const {category_error, list_categories,category_loading} = useSelector(state => state.allCategories);
    const {isBeginning, isEnd} = hasSlide;

    const handleSelectCategoryIndex = (data) => {
         setIsCategoryIndex(data)
    }

    useEffect(() => {
        dispatch(ListCategories())
    }, [dispatch]);
  return (
    <section className='category-items'>
        {category_error && <p className="text-center text-danger">{category_error}</p>}
        {category_loading && <p>fetching categories...</p>}

        <div className='controlled-slider'>
            {
                !isBeginning ? (<button className="category-control-btn left" onClick={() => slider?.current?.slidePrev()}>
                <BsChevronLeft />
              </button>) : null
            }
            {
                !isEnd && list_categories.length > 0 ? 
               (<button className="category-control-btn right" onClick={() => slider?.current?.slideNext()}><BsChevronRight /></button>) : null
            }
            <Swiper 
                {...UploadSettings}
                onSwiper={it => (slider.current = it)}
                onSlideChange={(e) => {
                     setHasSlide({
                          isBeginning: e.isBeginning,
                          isEnd: e.isEnd
                     })
                }}
            >
                {
                    list_categories && list_categories.map((category, index) => (
                        <SwiperSlide key={category._id} onClick={() => handleSelectCategoryIndex(index)}>
                            <div className='slides cursor-pointer position-relative'>
                                {
                                    isCategoryIndex === index && <div className='check-category'>
                                    <Check size={12}/>
                                </div>
                                }
                                <img src={bgImages(category.name)} alt={`${category.name}`} />
                                <span>{category.name}</span>
                            </div>
                        </SwiperSlide>
                    ))
                }
            </Swiper>
           
        </div>
    </section>
  )
}

export default UploadCategories